import {INavegadorCheck, INavegadorRegistro} from "services-comun-ed/modules/services/navegadores/interface";
import {NavegadoresWebFrontendRequest} from "services-comun-ed/modules/services/navegadores/frontend-web";

import cookies from "../utils/cookies";
import {error} from "services-comun/modules/browser/log";
import dominio from "services-comun-ed/modules/portal/ed";

class Navegador {
    /* STATIC */
    private static _instance?: Promise<Navegador>;
    public static get instance(): Promise<Navegador> { return this._instance??=this.init(); }

    private static async init(): Promise<Navegador> {
        const data: INavegadorRegistro = {
            hash: cookies.get("ed-check"),
            offset: new Date().getTimezoneOffset(),
        };
        const hash = await NavegadoresWebFrontendRequest.check(data);

        const salida = new this({
            hash,
            offset: data.offset,
        });
        salida.save();

        return salida;
    }

    /* INSTANCE */
    public get hash(): string { return this.data.hash; }

    protected constructor(private readonly data: INavegadorCheck) {

    }

    protected save(): void {
        const fecha = new Date();
        fecha.setUTCFullYear(fecha.getUTCFullYear()+1);

        cookies.set("ed-check", this.data.hash, fecha, dominio.cookies);
    }
}

const navegador = Navegador.instance;
navegador
    .then(async ()=>{})
    .catch(async (err)=>{
        error("Error iniciando check", err);
    });

export default navegador;
